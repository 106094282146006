import {combineReducers} from "redux";
import {i18nState} from "redux-i18n";
import {configureStore, getDefaultMiddleware} from "redux-starter-kit";
import reduxCookiesMiddleware from 'redux-cookies-middleware';
import preferenceReducer from '../slices/preference';
import authenticationReducer from '../slices/authentication';
import interviewReducer from '../slices/interview';
import recruiterReducer from '../slices/recruiter';
import programmeReducer from '../slices/programme';
import programmeSettingReducer from '../slices/programmeSetting';
import candidateListReducer from '../slices/candidateList'
import candidateReducer from '../slices/candidate'
import adminReducer from '../slices/admin'
import groupReducer from '../slices/group'
import {sessionReducer} from 'redux-react-session' 
import {setCookie} from '../utils/cookieUtils'

// state to persist in cookies
const cookiePathMapping = {
  'preference.language': { name: 'lang' },
  'preference.brightness': { name: 'brightness' },
  'preference.volume': { name: 'volume' },
  'preference.gain': { name: 'gain' },
  'preference.loginRole': { name: 'loginRole' },
  'authenticated.username': { name: 'username' },
  'authenticated.role': { name: 'role' },
  'authenticated.rememberMe': { name: 'remember_me' },
  'authenticated.serial': { name: 'serial' },
  'authenticated.token': { name: 'token' },
  'authenticated.s3Token': { name: 's3Token' },
  'authenticated.s3IdentityId': { name: 's3IdentityId' },
  'authenticated.validationCode': { name: 'validation_code' },
  'authenticated.adminToken': { name: 'adminToken' },
  // TODO include admin acting as method
  'authenticated.loginMethod': { name: 'loginMethod' },
  'authenticated.jobseekerCode': { name: 'jobseekerCode' },
  'authenticated.authenticationErrorCode': { name: 'authenticationErrorCode' },
  'authenticated.extraInfo.code': { name: 'extraInfo.code' },
  'authenticated.extraInfo.redirectUri': { name: 'extraInfo.redirectUri' },
  'authenticated.extraInfo.datafeedUrl': { name: 'extraInfo.datafeedUrl' },
  'interview.currentVideoInterviewRetryCount': {name: 'currentVideoInterviewRetryCount'},
  'interview.defaultTriggered': {name: 'defaultTriggered'},
};

const options = {

}

export default function configureNeufastStore(preloadedState = {}) {
  const reducer = combineReducers({
    i18nState: i18nState,
    preference: preferenceReducer,
    authenticated: authenticationReducer,
    interview: interviewReducer,
    recruiter: recruiterReducer,
    programme: programmeReducer,
    programmeSetting: programmeSettingReducer,
    candidateList: candidateListReducer,
    candidate: candidateReducer,
    admin: adminReducer,
    group: groupReducer,
    session: sessionReducer
  });
  const isProd = process.env.NODE_ENV === 'production'
  const options = {
    logger: console.error,
    setCookie: (name, value) => setCookie(name, value, isProd, 'lax', false),
    defaultEqualityCheck: (a, b) => (a === b),
    defaultDeleteCheck: value => (typeof value === 'undefined'),
};

  const store = configureStore({
    reducer: reducer ,
    middleware:[
      ...getDefaultMiddleware(),
      reduxCookiesMiddleware(cookiePathMapping, options)
    ],
    preloadedState: preloadedState,
    devTools : !isProd
  });
  return store;
}
