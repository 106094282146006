const config = {
  version: '1.0.1',
  baseUrl: '',
  languages: ['en', 'tc', 'sc', 'th', 'ja', 'id', 'ms', 'vi', 'km','de','fr','kr','ta'],
  interviewLanguages: ['english', 'cantonese', 'mandarin', 'thai', 'japanese', 'indonesian', 'malay', 'vietnamese', 'khmer','german','french','korean','tagalog'],
  horizontalLogo: '/logo/Neufast_logo_horizontal.png',
  horizontalLightLogo: '/logo/Neufast_logo_horizontal_light.png',
  verticalLogo: '/logo/Neufast_logo_vertical.png',
  tr_jobId: [],
}

console.debug = () => {}
console.log = () => {}

export default config
